const cost_immobility = {
    name: 'cost_immobility',
    title: 'Оплата за простой',
    url: '/settings/cost_immobility',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.cost_immobility.list',
        read: 'v1.cost_immobility.read',
        create: 'v1.cost_immobility.create',
        update: 'v1.cost_immobility.update',
        delete: 'v1.cost_immobility.delete',
    },
    listColumns: [
        {
            name: 'cost',
            required: true,
            label: 'Сумма',
            align: 'left',
            field: 'cost',
            sortable: true,
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
    editColumns: [
        {
            name: 'cost',
            required: true,
            label: 'Сумма',
            align: 'left',
            field: 'cost',
            sortable: true,
            type: 'number',
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
};

export {
    cost_immobility,
};
